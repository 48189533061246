import { useContext, useEffect, useState } from "react";
import Navbar from "../../components/layout/Navbar";
import { OrgContext } from "../../context/OrgState";
import {
  BaseProps,
  KYCDoc,
  KYCDocType,
  OrgKYC,
  Organization,
  ToastType,
} from "../../types";
import Aadhar from "./kyc/Aadhar";
import KYCDocument from "./kyc/KYCDocument";
import { Service } from "../../service";
import { showToast } from "../../util/Toast";

export default function OrgKYCDashboard(props: BaseProps) {
  const [org, setOrg] = useState<Organization | null>(null);
  const [orgKYC, setOrgKYC] = useState<OrgKYC | null>(null);
  const [loading, setLoading] = useState(false);

  const orgContext = useContext(OrgContext);

  useEffect(() => {
    //@ts-ignore
    const id = props.match.params["id"];
    const init = async () => {
      try {
        const [{ result: org }, { result: orgKYC }] = await Promise.all([
          Service.getOrgById(id),
          Service.getOrgKYC(id),
        ]);

        setOrg(org);
        setOrgKYC(orgKYC);
        setLoading(false);
      } catch (err: any) {
        showToast(
          ToastType.ERROR,
          err.message ? err.message : "Something went wrong! Please try again!"
        );
        console.log(err);
        setLoading(false);
      }
    };

    init();
  }, []);

  return (
    <div className="container" style={{ marginTop: "6rem" }}>
      <Navbar isLoggedIn={true}></Navbar>
      <div>
        {orgKYC?.docs.map((kycDoc: KYCDoc) => {
          if (kycDoc.type === KYCDocType.AADHAR) {
            return <Aadhar doc={kycDoc} org={org!} {...props}></Aadhar>;
          } else {
            return (
              <KYCDocument doc={kycDoc} org={org!} {...props}></KYCDocument>
            );
          }
        })}
      </div>
      <br></br>
    </div>
  );
}
