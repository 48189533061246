import { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/layout/Navbar";
import { Service } from "../service";
import { BaseProps, Organization, ToastType } from "../types";
import { showToast } from "../util/Toast";

export default function Dashboard(props: BaseProps) {
  const [orgs, setOrgs] = useState<Organization[] | null>(null);

  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const PAGE_SIZE = 20;
  const [loading, setLoading] = useState(true);

  const [showFilters, setShowFilters] = useState(false);
  const [selectedField, setSelectedField] = useState("name");
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    async function init() {
      try {
        const { result } = await Service.getOrgList(pageNumber, PAGE_SIZE, {});
        const { orgs, totalCount, totalPages } = result;
        setTotalPages(totalPages);
        setTotalCount(totalCount);
        setOrgs(orgs);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        if (err && err.code === "EC-UN-001") {
          props.history.push("/login");
        }
        showToast(
          ToastType.ERROR,
          err.message ? err.message : "Something went wrong! Please try again!"
        );
      }
    }

    init();
  }, []);

  const handleSearch = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      const { result } = await Service.getOrgList(pageNumber, PAGE_SIZE, {
        [selectedField]: valueSearch,
      });
      setOrgs(result.orgs);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
    }
  };

  return loading ? (
    <div>
      <Navbar isLoggedIn={true}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <span
          className="spinner-border spinner-border-sm me-2 mr-2 mt-2
      "
          role="status"
        ></span>
      </div>
    </div>
  ) : (
    <div>
      <Navbar isLoggedIn={true}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="container mt-4">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right">
              <button
                className="btn btn-secondary"
                onClick={() => setShowFilters(!showFilters)}
                style={{ width: "200px" }}
              >
                <i className="fa fa-filter"></i>
                {"  "}
                Filter
              </button>
            </div>
          </div>

          {showFilters && (
            <div className="mt-3">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setSelectedField("name");
                      setValueSearch("");
                    }}
                    style={{ width: "200px" }}
                  >
                    <i className="fa fa-times-circle"></i>
                    {"  "}
                    Clear All Filters
                  </button>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    value={selectedField}
                    onChange={(e) => setSelectedField(e.target.value)}
                  >
                    <option value="name">Name</option>
                    <option value="tenant">Tenant</option>
                  </select>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter value"
                    value={valueSearch}
                    onChange={(e) => setValueSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-center">
                  <button
                    className="btn btn-primary"
                    onClick={handleSearch}
                    style={{ width: "250px" }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <br></br>
        <h3>Organizations</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Org Type</th>
              <th scope="col">KYC Status</th>

              <th scope="col">Created At</th>
              <th scope="col">Updated At</th>
              <th scope="col">View</th>
            </tr>
          </thead>
          <tbody>
            {orgs && orgs.length ? (
              orgs.map((org, index: number) => {
                return (
                  <tr>
                    <th scope="row">{pageNumber - 1 + index + 1}</th>

                    <td>{org.name}</td>
                    <td>{org.orgType}</td>
                    <td>{org.kycStatus}</td>

                    <td>{new Date(org.createdAt).toDateString()}</td>
                    <td>{new Date(org.updatedAt).toDateString()}</td>
                    <td>
                      <Link
                        className="btn btn-primary btn-block"
                        to={`/org/${org.id}`}
                      >
                        Details
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div></div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
