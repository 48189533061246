import React, { createContext, useState, PropsWithChildren } from "react";
import { OrgKYC, Organization } from "../types";

type orgProps = {
  org: Organization | null;
  orgKYC: OrgKYC | null;
  setOrg: Function;
  setOrgKYC: Function;
};

export const OrgContext = createContext<orgProps>({
  org: null,
  orgKYC: null,
  setOrg: () => {},
  setOrgKYC: () => {},
});

const OrgProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [org, setOrg] = useState<Organization | null>(null);
  const [orgKYC, setOrgKYC] = useState<OrgKYC | null>(null);

  return (
    <OrgContext.Provider
      value={{
        org,
        orgKYC,
        setOrg,
        setOrgKYC,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export default OrgProvider;
