import "./onboarding.css";

import React, { SyntheticEvent, useContext, useEffect, useState } from "react";

import Navbar from "../../components/layout/Navbar";
import { UserContext } from "../../context/UserState";
import { Service } from "../../service";
import { BaseProps, ToastType } from "../../types";
import { sanitizePhoneNumber } from "../../util";
import { showToast } from "../../util/Toast";

export default function VerifyPhoneNumber(props: BaseProps) {
  const userContext = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [otp, setOtp] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        if (userContext.user?.isPhoneVerified) {
          props.history.push("/dashboard");
          return;
        }

        const { result: user } = await Service.getUser();
        userContext.setUser(user);

        if (user.isPhoneVerified) {
          props.history.push("/dashboard");
          return;
        }
      } catch (err: any) {
        if (err && err.code === "EC-UN-001") {
          props.history.push("/login");
        }
        props.history.push("/dashboard");
      }
    };

    init();
  }, []);

  const handleOTPSubmission = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!otp || otp === "" || otp.length !== 6) {
        showToast(ToastType.ERROR, "Invalid OTP");
        return;
      }

      await Service.verifyPhoneOTP({
        otp,
      });

      showToast(ToastType.SUCCESS, "OTP Verified Successfully");

      setLoading(false);

      props.history.push("/dashboard");
    } catch (err: any) {
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );

      if (err && err.code === "EC-UN-001") {
        props.history.push("/login");
      }
      setLoading(false);
    }
  };

  const handlePhoneNumberSubmission = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!phoneNumber || phoneNumber === "") {
        showToast(ToastType.ERROR, "Phone number cannot be empty !");
        return;
      }

      const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber);

      if (!sanitizedPhoneNumber) {
        showToast(ToastType.ERROR, "Invalid phone number");
        return;
      }

      await Service.initiatePhoneNumberVerification({
        phoneNumber: sanitizedPhoneNumber,
      });

      showToast(ToastType.SUCCESS, "OTP Sent Successfully");
      setIsOtpSent(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);

      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );

      if (err && err.code && err.code === "EC-031") {
        setLoading(false);
        props.history.push("/dashboard");
      }
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={true} />
      <div className="container login">
        <br></br>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-form">
            <h4 className="login-title">🔒 Verify your Phone Number 🚀</h4>

            <form className="form-group d-flex flex-column">
              <label className="w-75 align-self-center">Phone Number</label>
              <input
                className="form-control w-75 align-self-center"
                placeholder="Phone Number"
                type="number"
                name="email"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber ? phoneNumber : undefined}
                disabled={isOtpSent}
              />
              <br></br>

              {!isOtpSent ? (
                <button
                  className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
                    loading ? "disabled" : ""
                  }`}
                  onClick={handlePhoneNumberSubmission}
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm me-2 mr-2"
                      role="status"
                    ></span>
                  )}
                  {loading ? "Loading..." : "Submit"}
                </button>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              {isOtpSent ? (
                <React.Fragment>
                  <label className="w-75 align-self-center">OTP🔑</label>
                  <input
                    className="form-control w-75 align-self-center"
                    placeholder="OTP"
                    type="password"
                    name="password"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp ? otp : undefined}
                  />
                  <br />{" "}
                  <button
                    className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
                      loading ? "disabled" : ""
                    }`}
                    onClick={handleOTPSubmission}
                    disabled={loading}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm me-2 mr-2"
                        role="status"
                      ></span>
                    )}
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </form>

            <br></br>
            <div className="login-form-footer-info">
              <p>Need Help ? Please contact support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
