import "./auth.css";

import { SyntheticEvent, useContext, useState } from "react";

import { Link } from "react-router-dom";

import Navbar from "../../components/layout/Navbar";
import { UserContext } from "../../context/UserState";
import { Service } from "../../service";
import { httpClient } from "../../service/httpClient";
import { BaseProps, ToastType } from "../../types";
import { showToast } from "../../util/Toast";

export default function Login(props: BaseProps) {
  const userContext = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleLogin = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { email, password } = user;
      if (email === "" || password === "" || !email || !password) {
        showToast(ToastType.ERROR, "Email and Password cannot be empty !");
        return;
      }

      const { result } = await Service.login({
        email,
        password,
      });

      userContext.setUser(result);
      localStorage.setItem("accessToken", result.accessToken);
      httpClient.initializeAuthorizedClient();

      if (!result.isPhoneVerified || !result.phoneNumber) {
        setLoading(false);
        props.history.push("/verify/phone");
        return;
      }

      setLoading(false);
      props.history.push("/dashboard");
    } catch (err: any) {
      setLoading(false);
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={false} />
      <div className="container login" style={{ marginTop: "4rem" }}>
        <br></br>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-form">
            <h4 className="login-title">🔒 Login</h4>

            <p className="login-desc">Get started with ID Secure 🚀</p>

            <form className="form-group d-flex flex-column">
              <label className="w-75 align-self-center">Email 📧</label>
              <input
                className="form-control w-75 align-self-center"
                placeholder="Email"
                type="text"
                name="email"
                onChange={onChange}
                value={user.email}
              />
              <br></br>
              <label className="w-75 align-self-center">Password 🔑</label>
              <input
                className="form-control w-75 align-self-center"
                placeholder="Password"
                type="password"
                name="password"
                onChange={onChange}
                value={user.password}
              />

              <br />
              <button
                className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
                  loading ? "disabled" : ""
                }`}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2 mr-2"
                    role="status"
                  ></span>
                )}
                {loading ? "Loading..." : "Submit"}
              </button>
            </form>

            <br></br>
            <div className="login-form-footer-info">
              <div>
                By Logging in, you agree to our{" "}
                <Link to="/terms-of-service">Terms of Service</Link> and our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
                <br></br>
                <div>
                  Didn't recieve a verification email?{" "}
                  <Link to="/resend-verification-email">
                    Resend Verification Email
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
