import React from "react";

import { RouteComponentProps } from "react-router-dom";

export enum OnboardingSteps {
  VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER",
  CREATE_ORG = "CREATE_ORG",
  COMPLETE_KYC = "COMPLETE_KYC",
}

export enum ToastType {
  "SUCCESS" = "SUCCESS",
  "ERROR" = "ERROR",
}

export enum KYCStatus {
  CREATED = "CREATED",
  DRAFT = "DRAFT",
  IN_REVIEW = "IN_REVIEW",
  SUCCESSFUL = "SUCCESSFUL",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}

export enum OrderItem {
  KYC_CREDITS = "KYC_CREDITS",
}

export enum OrderStatus {
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

export enum PaymentStatus {
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

export enum KYCDocStatus {
  CREATED = "CREATED",
  IN_REVIEW = "IN_REVIEW",
  PROCESSING = "PROCESSING",
  SUCCESSFUL = "SUCCESSFUL",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  NO_ACTION_REQUIRED = "NO_ACTION_REQUIRED",
  FAILED = "FAILED",
}

export enum KYCDocType {
  CERTIFICATE_OF_INCORPORATION = "CERTIFICATE_OF_INCORPORATION",
  COMPANY_PAN_CARD = "COMPANY_PAN_CARD",
  COMPANY_BANK_STATEMENT = "COMPANY_BANK_STATEMENT",
  GST_CERTIFICATE = "GST_CERTIFICATE",
  TRUST_DEED = "TRUST_DEED",
  HUF_DEED = "HUF_DEED",
  AADHAR = "AADHAR",
  INDIVIDUAL_PAN = "INDIVIDUAL_PAN",
  VOTER_ID = "VOTER_ID",
  DRIVING_LICENSE = "DRIVING_LICENSE",
  PARTNERSHIP_DEED = "PARTNERSHIP_DEED",
  TIN_REGISTRATION = "TIN_REGISTRATION",
  VAT_REGISTRATION = "VAT_REGISTRATION",
  TRADE_LICENSE = "TRADE_LICENSE",
  CANCELLED_CHEQUE = "CANCELLED_CHEQUE",
}

export enum OrgType {
  LLP = "LLP",
  NGO = "NGO",
  PARTNERSHIP = "PARTNERSHIP",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  TRUST = "TRUST",
  HUF = "HUF",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum UserRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  STAFF = "STAFF",
}

export enum KYCDocVerificationMethod {
  ID_NUMBER_API = "ID_NUMBER_API",
  MANUAL_FILE = "MANUAL_FILE",
  BOTH = "BOTH",
}

export interface KYCConfig {
  name: string;
  description?: string;
  //AND
  documents: {
    [key in string]: {
      // OR
      document: KYCDocType;
      verificationMethod: KYCDocVerificationMethod;
    }[];
  };
}

export interface KYCDoc {
  id: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  kycId: string | null;
  orgKYCId: string;
  groupId: string;
  status: KYCDocStatus;
  type: KYCDocType;
  idNumber: string | null;
  nameOnId: string | null;
  dob: string | null;
  file: string | null;
  fileUploadedAt: string | null;
  verificationMethod: KYCDocVerificationMethod;
  isExternalVerification: boolean;
  externalVerificationPartner: string | null;
  externalVerificationLink: string | null;
  externalVerificationRefNumber: string | null;
  externalData: object | null | AadharExternalData | PANExternalData;
  verifiedAt: string | null;
  verifiedBy: string | null;
  remarks: string | null;
  purpose: string | null;
  consent: string | null;
  failureCode: string | null;
  metadata: object | null;
  name: string | null;
}

export interface OrgKYC {
  id: string;
  createdAt: string; // ISO time stamp in GMT - 2023-12-18T17:26:57.873Z
  updatedAt: string;
  version: number;
  orgId: string;
  orgType: OrgType;
  kycStatus: KYCStatus;
  statusUpdatedAt: string;
  remarks: string | null;
  name: string;
  docs: KYCDoc[];
}

export interface User {
  id: string;
  createdAt: string; // ISO time stamp in GMT - 2023-12-18T17:26:57.873Z
  updatedAt: string;
  version: number;
  phoneNumber: string | null;
  name: string;
  email: string;
  isEmailVerified: boolean;
  emailVerifiedAt: string | null;
  isPhoneVerified: boolean;
  phoneVerifiedAt: string | null;
  role: string;
  orgId: string | null;
}

export interface OrgUsers {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  role: string;
}

export interface Organization {
  id: string;
  createdAt: string; // ISO time stamp in GMT - 2023-12-18T17:26:57.873Z
  updatedAt: string;
  version: number;
  name: string;
  orgType: OrgType;
  kycStatus: KYCStatus | null;
  description: string | null;
  intentOfUsage: string | null;
  tenant?: string;
}

export interface Onboarding {
  isPhoneNumberVerified: boolean;
  isOrgCreated: boolean;
  isKYCInReviewOrSuccessful: boolean;
  onboardingSteps: {
    step: OnboardingSteps;
    doesUserHaveAccess: boolean;
  }[];
}

export interface BaseProps extends React.PropsWithChildren {
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
}

export const AadharKYCConfig: KYCConfig = {
  name: "Aadhar Verification",
  documents: {
    AADHAR: [
      {
        document: KYCDocType.AADHAR,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
  },
};

export const PANKYCConfig: KYCConfig = {
  name: "PAN Number Verification",
  documents: {
    INDIVIDUAL_PAN: [
      {
        document: KYCDocType.INDIVIDUAL_PAN,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
  },
};

export const DLKYCConfig: KYCConfig = {
  name: "Driving License Verification",
  documents: {
    DRIVING_LICENSE: [
      {
        document: KYCDocType.DRIVING_LICENSE,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
  },
};

export const VoterIdKYCConfig: KYCConfig = {
  name: "Voter ID Verification",
  documents: {
    VOTER_ID: [
      {
        document: KYCDocType.VOTER_ID,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
  },
};

export const AadharAndPANKYCConfig: KYCConfig = {
  name: "Aadhar and PAN Verification",
  documents: {
    INDIVIDUAL_PAN: [
      {
        document: KYCDocType.INDIVIDUAL_PAN,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
    AADHAR: [
      {
        document: KYCDocType.AADHAR,
        verificationMethod: KYCDocVerificationMethod.ID_NUMBER_API,
      },
    ],
  },
};

export interface AadharExternalData {
  data: {
    image: string;
    proofOfAddress: {
      vtc: string;
      house: string;
      state: string;
      careOf: string;
      street: string;
      country: string;
      pincode: string;
      district: string;
      landmark: string;
      locality: string;
      postOffice: string;
      subDistrict: string;
    };
    proofOfIdentity: {
      dob: string; //DD-MM-YYYY
      name: string;
      gender: "M" | "F" | string;
      hashedEmail: string;
      hashedMobileNumber: string;
      email?: string | null;
      mobileNumber?: string | null;
    };
  };
}

export interface PANExternalData {
  kycStatus: "SUCCESS" | "FAILURE" | string | null;
  kycResult: {
    idNumber: string;
    idStatus: "VALID" | "INVALID" | string;
    category: "INDIVIDUAL OR PERSON" | "BUSINESS" | string;
    panStatus: "VALID" | "INVALID" | string;
    lastName: string;
    firstName: string;
    fullName: string;
    idHolderTitle: string;
    idLastUpdated: string | Date;
    aadhaarSeedingStatus: "Successful" | "failure" | string;
  };
}

export interface VoterIdExternalData {
  status: "SUCCESS" | "FAILURE" | string;
  kycStatus: "SUCCESS" | "FAILURE" | string;
  message: string;
  kycResult: {
    epicNo: string;
    name: string;
    nameInVernacular: string;
    gender: "M" | "F" | string;
    age: number;
    relativeName: string;
    relativeNameInVernacular: string;
    relativeRelationType: string;
    houseNumber: string;
    partOrLocationInConstituency: string;
    partNumberOrLocationNumberInConstituency: string;
    parliamentaryConstituency: string;
    assemblyConstituency: string;
    sectionOfConstituencyPart: number;
    cardSerialNumberInPollingList: number;
    lastUpdateDate: string; //ISO TIMESTAMP
    status: "N" | string;
    pollingBoothDetails: {
      name: string;
      nameVernacular: string;
      number: number;
    };
    address: {
      districtCode: number;
      districtName: string;
      districtNameVernacular: string;
      state: string;
      stateCode: string;
    };
    emailId: string;
    mobileNumber: string;
    dateOfBirth: string;
    district: string;
    state: string;
    stateCode: string;
    pollingBoothCoordinates: string;
    pollingBoothAddress: string;
    pollingBoothNumber: string;
    id: string;
  };
}

export interface DrivingLicenseExternalData {
  kycStatus: "SUCCESS" | "FAILURE" | string;
  status: "SUCCESS" | "FAILURE" | string;
  message: string;
  kycResult: {
    addresses: [
      {
        addressLine: string;
        completeAddress: string;
        country: string;
        district: string;
        pin: string;
        state: string;
        type: string;
      },
      {
        addressLine: string;
        completeAddress: string;
        country: string;
        district: string;
        pin: string;
        state: string;
        type: string;
      }
    ];
    allClassOfVehicle: [];
    drivingLicenseNumber: string;
    dateOfBirth: string;
    endorseDate: string;
    endorseNumber: string;
    name: string;
    fatherOrHusbandName: string;
    status: "ACTIVE" | string;
    validFrom: string;
    validTo: string;
  };
}
