import { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import OrgProvider from "./context/OrgState";
import UserProvider from "./context/UserState";
import PrivateRoute from "./helper/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import ResendEmailVerification from "./pages/auth/ResendVerificationEmail";
import ResentEmailVerification from "./pages/auth/ResentVerificationEmail";
import Verify from "./pages/auth/Verify";
import OrgDashboard from "./pages/org/OrgDashboard";
import "react-toastify/dist/ReactToastify.css";

import CIN from "./pages/org/kyc/KYCDocument";
import VerifyPhoneNumber from "./pages/onboarding/VerifyPhoneNumber";
import Reward from "./pages/org/Reward";
import OrgKYCDashboard from "./pages/org/OrgKYCDashboard";

function App() {
  return (
    <UserProvider>
      <OrgProvider>
        <Fragment>
          <ToastContainer />
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login}></Route>

              <Route
                exact
                path="/resend-verification-email"
                component={ResendEmailVerification}
              ></Route>
              <Route
                exact
                path="/resent-email-verification"
                component={ResentEmailVerification}
              ></Route>
              <Route
                exact
                path="/verify/:id/:verificationToken"
                component={Verify}
              ></Route>

              <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/org/:id"
                component={OrgDashboard}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/org/:id/kyc"
                component={OrgKYCDashboard}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/org/:id/rewards"
                component={Reward}
              ></PrivateRoute>
              <PrivateRoute
                exact
                component={VerifyPhoneNumber}
                path="/verify/phone"
              ></PrivateRoute>
            </Switch>
          </BrowserRouter>
        </Fragment>
      </OrgProvider>
    </UserProvider>
  );
}

export default App;
