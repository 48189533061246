import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./auth.css";
import Navbar from "../../components/layout/Navbar";

import { BaseProps, ToastType } from "../../types";
import { showToast } from "../../util/Toast";
import { Service } from "../../service";

export default function ResendEmailVerification(props: BaseProps) {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (email === "") {
        showToast(ToastType.ERROR, "Email cannot be empty !");
        return;
      }

      await Service.resendVerificationEmail({ email });

      showToast(
        ToastType.SUCCESS,
        "Verification email sent Successfully, Please check all your mail tabs and even spam !"
      );
      setLoading(false);
      props.history.push("/resent-email-verification");
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong. Please try again!"
      );
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={false} />

      <div className="container login">
        <br></br>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-form">
            <h4 className="login-title">🔒 Resend Verification Email</h4>

            <p className="login-desc">ID Secure 🚀</p>

            <form className="form-group d-flex flex-column ">
              <input
                className="form-control w-75 align-self-center"
                placeholder="Email Address"
                type="text"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <br></br>

              <button
                className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
                  loading ? "disabled" : ""
                }`}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2 mr-2"
                    role="status"
                  ></span>
                )}
                {loading ? "Loading..." : "Submit"}
              </button>
            </form>

            <br></br>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-img-container">
            <img
              src="https://res.cloudinary.com/dhw5dya3h/image/upload/v1640626500/illustration_register_emr222.png"
              className="img-fluid"
              alt="Login Illustration"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
