import React, { createContext, useState, PropsWithChildren } from "react";
import { User } from "../types";

type userContextProps = {
  user: User | null;
  setUser: Function;
};

export const UserContext = createContext<userContextProps>({
  user: null,
  setUser: () => {},
});

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
