import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./auth.css";
import Navbar from "../../components/layout/Navbar";

import { showToast } from "../../util/Toast";
import { BaseProps, ToastType } from "../../types";
import { Service } from "../../service";

export default function Verify(props: BaseProps) {
  //@ts-ignore
  const id = props.match.params["id"];
  //@ts-ignore
  const verificationToken = props.match.params["verificationToken"];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const init = async () => {
      try {
        await Service.verifyEmail({
          id,
          verificationToken,
        });
        setLoading(false);
        showToast(
          ToastType.SUCCESS,
          "Email verified successfully. Please login !"
        );
      } catch (err: any) {
        showToast(
          ToastType.ERROR,
          err.message
            ? err.message
            : "Something went wrong. Please try again if the issue persists please contact support!"
        );
      }
    };

    init();
  }, []);

  return (
    <div>
      <Navbar isLoggedIn={false} />
      <div className="container login">
        <br></br>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 verification-card">
            <h4 className="login-title email-verification-title">
              🔒 Email Verification Successful
            </h4>

            <p className="login-desc">Get started with Neo Connect</p>

            <form className="form-group d-flex flex-column ">
              <br />
              <Link
                className="w-75 btn btn-success btn-block align-self-center btn-login-submit"
                to="/login"
              >
                Login
              </Link>
            </form>

            <br></br>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-img-container">
            <img
              src="https://res.cloudinary.com/dhw5dya3h/image/upload/v1640626500/illustration_register_emr222.png"
              className="img-fluid"
              alt="Login Illustration"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
