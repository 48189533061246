import Navbar from "../../../components/layout/Navbar";
import { Service } from "../../../service";
import {
  BaseProps,
  KYCDoc,
  ToastType,
  KYCDocStatus,
  Organization,
} from "../../../types";
import { showToast } from "../../../util/Toast";
import "./kyc.css";

import React, { ChangeEvent, SyntheticEvent, useState } from "react";

export default function KYCDocument(
  props: BaseProps & {
    doc: KYCDoc;
    org: Organization;
  }
) {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [idNumber, setIdNumber] = useState<string | null>(null);
  const [dob, setDob] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [status, setStatus] = useState<KYCDocStatus>(KYCDocStatus.CREATED);
  const [metadataString, setMetadataString] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<object | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedFile) {
      showToast(ToastType.ERROR, "Please upload a file");
      setLoading(false);
      return;
    }

    const { doc, org } = props;

    try {
      if (metadataString) {
        try {
          const _jsonMetadata = JSON.parse(metadataString);
          setMetadata(_jsonMetadata);
        } catch (err: any) {
          showToast(ToastType.ERROR, "Invalid JSON");
          return;
        }
      }

      const { result } = await Service.uploadFileForOrgKYC(
        org.id,
        doc.orgKYCId,
        doc.id,
        doc.type,
        {
          file: selectedFile,
        }
      );

      await Service.updateOrgKYCDoc(org.id, doc.orgKYCId, doc.id, {
        consent: true,
        idType: doc.type,
        verificationMethod: doc.verificationMethod,
        file: result.key,
        idNumber: idNumber || undefined,
        dob: dob || undefined,
        name: name || undefined,
        metadata: metadata || undefined,
      });

      setLoading(false);
      showToast(ToastType.SUCCESS, "Uploaded Successfully");
      window.location.reload();
    } catch (err: any) {
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
      console.log(err);
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  };

  function getUploadComponent(doc: KYCDoc) {
    switch (doc.status) {
      case KYCDocStatus.REJECTED:
      case KYCDocStatus.FAILED:
      case KYCDocStatus.CREATED: {
        return (
          <form>
            <label className="w-75 align-self-center">{doc.type} File</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={handleFileChange}
              />

              <label className="custom-file-label" htmlFor="customFile">
                {selectedFile?.name ? selectedFile.name : "Choose File"}
              </label>
              <br></br>
            </div>

            <br></br>

            <label className="w-75 align-self-center">ID Number</label>
            <input
              className="form-control w-75 align-self-center"
              placeholder={doc.type}
              type="text"
              name="ID Number"
              onChange={(e) => setIdNumber(e.target.value)}
              value={idNumber ? idNumber : undefined}
            />
            <br></br>

            <label className="w-75 align-self-center">Name on ID {"  "}</label>
            <input
              className="form-control w-75 align-self-center"
              placeholder="Name as on ID"
              type="text"
              name="Name"
              onChange={(e) => setName(e.target.value)}
              value={name ? name : undefined}
            />
            <br></br>
            <label className="w-75 align-self-center">
              Metadata - the input should be a valid JSON{"  "}
            </label>
            <textarea
              className="form-control w-75 align-self-center"
              value={metadataString || undefined}
              onChange={(e) => setMetadataString(e.target.value)}
              placeholder="Metadata"
              rows={10}
              cols={50}
            />
            <br />
            <button
              className={` btn btn-success btn-block align-self-center btn-login-submit ${
                loading || selectedFile?.name ? "disabled" : ""
              }`}
              onClick={submit}
              disabled={loading}
              style={{ marginTop: "2rem" }}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm me-2 mr-2"
                  role="status"
                ></span>
              )}
              {loading ? "Loading..." : "Submit"}
            </button>
            <br></br>
          </form>
        );
      }
      case KYCDocStatus.IN_REVIEW:
      case KYCDocStatus.PROCESSING:
      case KYCDocStatus.SUCCESSFUL:
      case KYCDocStatus.CANCELLED:
      case KYCDocStatus.NO_ACTION_REQUIRED: {
        return (
          <form>
            <br></br>

            <label className="w-75 align-self-center">ID Number</label>
            <input
              className="form-control w-75 align-self-center"
              type="text"
              name="ID Number"
              value={doc.idNumber || undefined}
              disabled={true}
            />
            <br></br>

            <label className="w-75 align-self-center">Name on ID {"  "}</label>
            <input
              className="form-control w-75 align-self-center"
              type="text"
              name="Name"
              value={doc.name || undefined}
              disabled={true}
            />

            <br></br>

            <button className="w-75 btn btn-info">View Document</button>
            <h1 className="badge badge-success">{}</h1>
          </form>
        );
      }
    }
  }

  const updateStatus = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (!status) {
        showToast(ToastType.ERROR, "Invalid Status");
        return;
      }

      const { doc, org } = props;

      await Service.updateKYCDocStatus({
        orgId: org.id,
        orgKYCId: doc.orgKYCId,
        kycDocId: doc.id,
        status,
        metadata: undefined,
      });
      showToast(ToastType.SUCCESS, "Successfully updated KYC doc status");
      setLoading(false);
      window.location.reload();
      return;
    } catch (err: any) {
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="card">
          <div className="card-body">
            <p className="card-title">{props.doc.type}</p>
            <div className="row no-gutters">
              <div className="col-md-6">
                <div className="card-body">{getUploadComponent(props.doc)}</div>
              </div>
              <div
                className="col-md-1"
                style={{
                  borderLeft: "border-left: 10px solid black",
                  height: "100%",
                }}
              ></div>
              <div className="col-md-5">
                <div className="card-body">
                  <h5 className="card-title">
                    Status :{" "}
                    <h1 className="badge badge-success">{props.doc.status}</h1>
                  </h5>
                  {props.doc.status !== KYCDocStatus.SUCCESSFUL ? (
                    <form>
                      <label className="w-75 align-self-center">
                        Status <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setStatus(e.target.value as KYCDocStatus)
                        }
                        defaultValue={KYCDocStatus.CREATED}
                      >
                        {Object.values(KYCDocStatus).map((status) => {
                          return <option value={status}>{status}</option>;
                        })}
                      </select>
                      <br></br>
                      <button
                        className={` btn btn-success btn-block align-self-center btn-login-submit ${
                          loading || selectedFile?.name ? "disabled" : ""
                        }`}
                        onClick={updateStatus}
                        disabled={loading}
                        style={{ marginTop: "2rem" }}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm me-2 mr-2"
                            role="status"
                          ></span>
                        )}
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </form>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
