import Navbar from "../../../components/layout/Navbar";
import { Service } from "../../../service";
import {
  BaseProps,
  KYCDoc,
  ToastType,
  KYCDocStatus,
  Organization,
} from "../../../types";
import { showToast } from "../../../util/Toast";
import "./kyc.css";

import React, { ChangeEvent, SyntheticEvent, useState } from "react";

export default function Aadhar(
  props: BaseProps & {
    doc: KYCDoc;
    org: Organization;
  }
) {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [idNumber, setIdNumber] = useState<string | null>(null);
  const [dob, setDob] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedFile) {
      showToast(ToastType.ERROR, "Please upload a file");
      setLoading(false);
      return;
    }

    const { doc, org } = props;

    try {
      const { result } = await Service.uploadFileForOrgKYC(
        org.id,
        doc.orgKYCId,
        doc.id,
        doc.type,
        {
          file: selectedFile,
        }
      );

      await Service.updateOrgKYCDoc(org.id, doc.orgKYCId, doc.id, {
        consent: true,
        idType: doc.type,
        verificationMethod: doc.verificationMethod,
        file: result.key,
        idNumber: idNumber || undefined,
        dob: dob || undefined,
        name: name || undefined,
      });

      setLoading(false);
      showToast(ToastType.SUCCESS, "Uploaded Successfully");
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  };

  function getUploadComponent(doc: KYCDoc) {
    switch (doc.status) {
      case KYCDocStatus.REJECTED:
      case KYCDocStatus.CREATED: {
        return (
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label className="w-75 align-self-center">
              {doc.type} File<span style={{ color: "red" }}>*</span>
            </label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={handleFileChange}
              />

              <label className="custom-file-label" htmlFor="customFile">
                {selectedFile?.name ? selectedFile.name : "Choose File"}
              </label>
            </div>
          </div>
        );
      }
      case KYCDocStatus.IN_REVIEW:
      case KYCDocStatus.PROCESSING:
      case KYCDocStatus.SUCCESSFUL:
      case KYCDocStatus.CANCELLED:
      case KYCDocStatus.NO_ACTION_REQUIRED: {
        return (
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <h1>Your document is {`${doc.status}`}</h1>
          </div>
        );
      }
      case KYCDocStatus.FAILED:
    }
  }

  return (
    <div>
      <div className="container login">
        <br></br>
        <div className="card login">
          <h4 className="card-title text-center">{props.doc.type}</h4>
          <br></br>
          <div className="row d-flex justify-content-center">
            {getUploadComponent(props.doc)}
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12">
              <br></br>

              <form>
                <br></br>

                <label className="w-75 align-self-center">
                  ID Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control w-75 align-self-center"
                  placeholder="Name as on Driving License card"
                  type="text"
                  name="ID Number"
                  onChange={(e) => setIdNumber(e.target.value)}
                  value={idNumber ? idNumber : undefined}
                />
                <br></br>

                <label className="w-75 align-self-center">
                  Name on ID {"  "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control w-75 align-self-center"
                  placeholder="Name as on Driving License card"
                  type="text"
                  name="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name ? name : undefined}
                />
                <br></br>
                <button
                  className={` btn btn-success btn-block align-self-center btn-login-submit ${
                    loading || selectedFile?.name ? "disabled" : ""
                  }`}
                  onClick={submit}
                  disabled={loading}
                  style={{ marginTop: "2rem" }}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm me-2 mr-2"
                      role="status"
                    ></span>
                  )}
                  {loading ? "Loading..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
