import React, { useContext, useEffect, useState } from "react";

import { Redirect, Route } from "react-router-dom";

import { UserContext } from "../context/UserState";
import { Service } from "../service";
import { httpClient } from "../service/httpClient";
import Loader from "./Loader";

//@ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const userContext = useContext(UserContext);

  useEffect(() => {
    async function init() {
      try {
        const accessToken: string | null = localStorage.getItem("accessToken");

        if (!accessToken) {
          setIsLoggedIn(false);
          return;
        }

        const { result } = await Service.validateToken(accessToken, {});

        if (!userContext.user) {
          userContext.setUser(result);
        }
        httpClient.initializeAuthorizedClient();
        setIsLoggedIn(true);
      } catch (err) {
        console.log(err);
        setIsLoggedIn(false);
      }
    }
    init();
  }, [isLoggedIn]);

  if (isLoggedIn === undefined) {
    return <Loader></Loader>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const data = {
          ...props,
          isLoggedIn,
        };
        return !isLoggedIn ? (
          <Redirect to="/login" {...props} />
        ) : (
          <Component {...data} />
        );
      }}
    />
  );
};

export default PrivateRoute;
