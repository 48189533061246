import { AxiosError, AxiosResponse } from "axios";

import { KYCDocStatus, KYCDocType } from "../types";
import { Error, IDSecureAPI } from "../types/api";
import { httpClient } from "./httpClient";

const handleAPIError = (err: any): Error => {
  if (err instanceof AxiosError) {
    if (err && err.response && err.response.data && err.response.data.error) {
      return err.response.data.error as Error;
    }
  }
  return {
    code: "EC-000",
    message: "Something went wrong! Please try again",
  } as Error;
};

export class Service {
  static async login(args: IDSecureAPI.Request.Login) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.Login> =
        await httpClient.unauthorized.post(`/v1/internal-user/login`, {
          ...args,
        });

      return result.data;
    } catch (err) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async verifyEmail(args: IDSecureAPI.Request.VerifyEmail) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.VerifyEmail> =
        await httpClient.unauthorized.post(
          `/v1/internal-user/verify/${args.id}/${args.verificationToken}`
        );

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async resendVerificationEmail(
    args: IDSecureAPI.Request.ResendVerificationEmail
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.ResendVerificationEmail> =
        await httpClient.unauthorized.post(`/v1/internal-user/email/resend`, {
          ...args,
        });

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async validateToken(
    accessToken: string,
    args: IDSecureAPI.Request.ValidateToken
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.ValidateToken> =
        await httpClient.unauthorized.get("/v1/internal-user/token/validate", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async initiatePhoneNumberVerification(
    args: IDSecureAPI.Request.InitiatePhoneNumberVerification
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.InitiatePhoneNumberVerification> =
        await httpClient.authorized.post(
          `/v1/internal-user/phone/verify/init`,
          {
            ...args,
          }
        );

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async verifyPhoneOTP(args: IDSecureAPI.Request.VerifyPhoneOTP) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.VerifyPhoneOTP> =
        await httpClient.authorized.post("/v1/internal-user/phone/verify", {
          ...args,
        });
      return result.data;
    } catch (err: any) {
      console.log({ err });
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrgDetails() {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.Org> =
        await httpClient.authorized.get(`/v1/org`);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrgById(orgId: string) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.Org> =
        await httpClient.authorized.get(`/v1/org/${orgId}`);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrgKYC(orgId: string) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.OrgKYC> =
        await httpClient.authorized.get(`/v1/org/${orgId}/kyc`);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async uploadFileForOrgKYC(
    orgId: string,
    kycId: string,
    docId: string,
    idType: KYCDocType,
    args: IDSecureAPI.Request.UploadFileForOrgKYC
  ) {
    try {
      const formData = new FormData();
      formData.append("orgId", orgId);
      formData.append("kycId", kycId);
      formData.append("entity", "KYCDoc");
      formData.append("idType", idType);
      formData.append("kycDocId", docId);
      formData.append("file", args.file);

      const formProps = Object.fromEntries(formData);
      console.log(formProps);

      const result: AxiosResponse<IDSecureAPI.Response.UploadFileForOrgKYC> =
        await httpClient.authorized.post(`/v1/media`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async updateOrgKYCDoc(
    orgId: string,
    orgKYCId: string,
    kycDocId: string,
    args: IDSecureAPI.Request.UpdateOrgKYCDoc
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.UpdateOrgKYCDoc> =
        await httpClient.authorized.patch(
          `/v1/org/${orgId}/kyc/${orgKYCId}/doc/${kycDocId}`,
          {
            ...args,
          }
        );
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getUser() {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.User> =
        await httpClient.authorized.get("/v1/internal-user");
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrdersForAOrg(skip: number = 0, limit: number = 20) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.GetOrdersForAOrg> =
        await httpClient.authorized.get(
          `/v1/order?skip=${skip}&limit=${limit}`
        );
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrderById(orderId: string) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.GetOrderById> =
        await httpClient.authorized.get(`/v1/order/${orderId}`);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getKYCCreditsLog(skip: number = 0, limit: number = 20) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.KYCCreditsLog> =
        await httpClient.authorized.get(
          `/v1/kyc-credits-log?skip=${skip}&limit=${limit}`
        );
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getOrgList(
    pageNumber: number = 1,
    pageSize: number = 20,
    filters: {
      name?: string;
      tenant?: string;
    }
  ) {
    let url = `/v1/org/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    const { name, tenant } = filters;

    if (name) {
      url = url + `&name=${name}`;
    }

    if (tenant) {
      url = url + `&tenant=${tenant}`;
    }

    try {
      const result: AxiosResponse<IDSecureAPI.Response.OrgList> =
        await httpClient.authorized.get(url);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async rewardKYCCreditsToAOrg(args: { orgId: string; amount: number }) {
    try {
      const { orgId, amount } = args;
      const result: AxiosResponse<IDSecureAPI.Response.RewardKYCCredits> =
        await httpClient.authorized.post("/v1/reward", {
          orgId,
          amount,
          type: "KYC_CREDITS",
        });
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async updateKYCDocStatus(args: {
    orgId: string;
    orgKYCId: string;
    kycDocId: string;
    status: KYCDocStatus;
    metadata?: Object;
  }) {
    try {
      const { orgId, orgKYCId, kycDocId, status, metadata } = args;
      const result: AxiosResponse<any> = await httpClient.authorized.patch(
        `/v1/org/${orgId}/kyc/${orgKYCId}/doc/${kycDocId}/status`,
        {
          status,
          metadata,
        }
      );

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }
}
