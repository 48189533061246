import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Navbar from "../../components/layout/Navbar";
import { OrgContext } from "../../context/OrgState";
import { Service } from "../../service";
import { BaseProps, OrgKYC, Organization, ToastType } from "../../types";
import { copyToClipboard, getKYCStatusTitle } from "../../util";
import { showToast } from "../../util/Toast";

export default function OrgDashboard(props: BaseProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [org, setOrg] = useState<Organization | null>(null);

  const orgContext = useContext(OrgContext);

  useEffect(() => {
    const getOrgById = async () => {
      try {
        //@ts-ignore
        const id = props.match.params["id"];

        const { result: org } = await Service.getOrgById(id);

        setOrg(org);
        orgContext.setOrg(org);
        setLoading(false);
      } catch (err: any) {
        console.log({ err });
        setLoading(false);
        showToast(
          ToastType.ERROR,
          err.message ? err.message : "Something went wrong! Please try again!"
        );
        if (err && err.code === "EC-UN-001") {
          props.history.push("/login");
        }
        props.history.push("/dashboard");
      }
    };

    getOrgById();
  }, []);
  return loading ? (
    <div>
      <Navbar isLoggedIn={true}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <span
          className="spinner-border spinner-border-sm me-2 mr-2 mt-2
      "
          role="status"
        ></span>
      </div>
    </div>
  ) : (
    <div>
      <Navbar isLoggedIn={true}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <h2>{org?.name}</h2>
        <table className="table table-bordered table-hover">
          <tbody>
            <tr>
              <td>ID</td>
              <td>
                {org?.id}{" "}
                <button
                  className="btn btn-link p-0 ml-2"
                  onClick={() => copyToClipboard(org?.id!)}
                  title="Copy KYC Link"
                >
                  <i className="fas fa-copy"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>Tenant</td>
              <td>{org?.tenant}</td>
            </tr>

            <tr>
              <td>Status</td>
              <td>{getKYCStatusTitle(org?.kycStatus!)}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{org?.description}</td>
            </tr>
            <tr>
              <td>Created At</td>
              <td>
                {org?.createdAt ? new Date(org.createdAt).toLocaleString() : ""}
              </td>
            </tr>
            <tr>
              <td>Update At</td>
              <td>
                {org?.updatedAt ? new Date(org.updatedAt).toLocaleString() : ""}
              </td>
            </tr>

            <tr>
              <td>KYC Details</td>
              <td>
                <Link className="btn btn-success" to={`/org/${org?.id}/kyc`}>
                  View Details
                </Link>
              </td>
            </tr>

            <tr>
              <td>Rewards</td>
              <td>
                <Link
                  className="btn btn-success"
                  to={`/org/${org?.id}/rewards`}
                >
                  View Details
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Footer></Footer>
    </div>
  );
}
