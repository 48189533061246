import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { BaseProps, KYCStatus, Organization, ToastType } from "../../types";
import Navbar from "../../components/layout/Navbar";
import { OrgContext } from "../../context/OrgState";
import { copyToClipboard, getKYCStatusTitle } from "../../util";
import { showToast } from "../../util/Toast";
import { Service } from "../../service";

export default function Reward(props: BaseProps) {
  const [org, setOrg] = useState<Organization | null>(null);
  const orgContext = useContext(OrgContext);
  const [kycCreditsToBeRewarded, setKYCCreditsToBeRewarded] =
    useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOrg(orgContext.org);
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      if (!kycCreditsToBeRewarded) {
        showToast(ToastType.ERROR, "Invalid kyc credits");
        return;
      }

      if (!org) {
        props.history.push("/dashboard");
        showToast(
          ToastType.ERROR,
          "Something does not seems to be right please try again"
        );
        return;
      }

      await Service.rewardKYCCreditsToAOrg({
        orgId: org.id,
        amount: kycCreditsToBeRewarded,
      });

      showToast(
        ToastType.SUCCESS,
        "KYC Credits will be credited to the org soon"
      );

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={true}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="row">
          <h3>{orgContext.org?.name}</h3>
        </div>
        <div className="row">
          <table className="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>ID</td>
                <td>
                  {org?.id}{" "}
                  <button
                    className="btn btn-link p-0 ml-2"
                    onClick={() => copyToClipboard(org?.id!)}
                    title="Copy KYC Link"
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>Tenant</td>
                <td>{org?.tenant}</td>
              </tr>

              <tr>
                <td>KYC Status</td>
                <td>{getKYCStatusTitle(org?.kycStatus!)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {org?.kycStatus !== KYCStatus.SUCCESSFUL ? (
          <div className="row">
            <div className="alert alert-warning">
              <i className="fa fa-info-circle"></i>
              {"  "} Rewards cannot be issued until KYC is successful
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <br></br>
        <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <h4>Reward KYC Credits</h4>
            <form className="login-form" style={{padding:'2rem'}}>
              <label className="w-75 align-self-center">
                KYC credits<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="form-control w-75 align-self-center"
                type="number"
                onChange={(e) =>
                  setKYCCreditsToBeRewarded(Number(e.target.value))
                }
              ></input>
              <br></br>
              <button
                className={` btn btn-success w-75 align-self-center btn-login-submit ${
                  loading ? "disabled" : ""
                }`}
                onClick={submit}
                disabled={loading}
                style={{ marginTop: "2rem" }}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2 mr-2"
                    role="status"
                  ></span>
                )}
                {loading ? "Loading..." : "Submit"}
              </button>
              <br></br>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
