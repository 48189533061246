import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./auth.css";
import Navbar from "../../components/layout/Navbar";

export default function ResentEmailVerification(props: any) {
  return (
    <div>
      <Navbar isLoggedIn={false} />
      <div className="container login">
        <br></br>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 verification-card">
            <h4 className="login-title email-verification-title">
              🔒 verification mail sent successfully
            </h4>

            <p className="login-desc">
              Please check your mail to verify your account to get
              started.Please also check promotions tab and spam folder for the
              Verification Email{" "}
            </p>

            <form className="form-group d-flex flex-column ">
              <br />
              <Link
                className="w-75 btn btn-success btn-block align-self-center btn-login-submit"
                to="/login"
              >
                Login
              </Link>

              <br />
            </form>

            <br></br>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login-img-container">
            <img
              src="https://res.cloudinary.com/dhw5dya3h/image/upload/v1640626500/illustration_register_emr222.png"
              className="img-fluid"
              alt="Login Illustration"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
